import React, { ChangeEvent, useState,useEffect} from 'react';
import Popover from '@mui/material/Popover';
import stellantislogoblue from "./Assets/ChatHome/logo blue.svg";
import usericon from "./Assets/ChatHome/chatbot-usericon.jpg";
import Input from '@mui/material/Input';
import { Link } from "react-router-dom";
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import { makeStyles,InputLabel,FormControl,Select,MenuItem, Grid, Paper,} from "@material-ui/core";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, PieChart, Pie, Cell, BarChart, Bar, LabelList, Text } from "recharts";

export default function Home(props){

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return(
        <>
        <div className='body-background'>
            <div className='header-background'>
                <div className="header-flex"><img src={stellantislogoblue} /></div>
                <div className='header-section2'>
                    <div className='header-text'>Welcome, <b>UserName</b></div>
                    <div>
                        <img aria-describedby={id} variant="contained" onClick={handleClick} src={usericon}/>
                        <Popover
                            id={id}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}>
                            <Link className='redirection-link' to="/"><div className='logout-text'>Logout</div></Link>
                        </Popover>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}