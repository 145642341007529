import React,{useState} from "react";
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { Grid, TextField } from "@material-ui/core";
import logo from "./Assets/ChatHome/logo blue.svg";
import { Link, useNavigate } from "react-router-dom";

export function Login(props){

    const [UserValue, setUserValue] = useState("user");
    const [UserClass, setUserClass] = useState("each-user-background-active");
    const [AdminClass, setAdminClass] = useState("each-user-background");
    const[UserName,setUserName]=useState('');
    const navigate = useNavigate();
    const UserNameFn=event=>{
    setUserName(event.target.value);
    var UserName =event.target.value;
    }
    const[Password,setPassword]=useState('');
    const PasswordFn=event=>{
        setPassword(event.target.value);
        var Password=event.target.value;
    }
    const Clear=event=>{
        if(UserName!==""||Password!==""){
            setUserName('')
            setPassword('')
        }
    }
    const Submit=event=>{
        if(UserName===""){
            alert("Please fill the UserName");
            // console.log("username empty");
            // <Stack sx={{ width: '100%' }} spacing={2}>
            //     <Alert severity="warning">Please fill the UserName</Alert>
            // </Stack>
            return
        }
        if(Password===""){
            alert("Please fill the Password");
        }
        else{
            alert("Login Successful");
            navigate('/home');
            setUserName('')
            setPassword('')
        }      
    }
    const changeUserActive =()=>{
        setUserClass("each-user-background-active");
        setAdminClass("each-user-background")
    }
    const changeAdminActive =()=>{
        setAdminClass("each-user-background-active");
        setUserClass("each-user-background");
    }

    return(
        <>
        <div className="Body-background">
            <div>
                <Grid container className="Rectangle">
                    <Grid item lg={6}>
                        <div className="logo">
                            <img src={logo} alt="stellantis logo" className="stellantisLogo" /> 
                            <div className='border-line'></div>
                            <div className="loginAndAdmin">
                                <div className="login">
                                    <span className={UserClass} onClick={()=>{setUserValue("user");changeUserActive();}}>LOGIN</span>
                                </div>
                                <div className="admin">
                                    <span className={AdminClass} onClick={()=>{setUserValue("admin");changeAdminActive();}}>ADMIN</span>
                                </div>
                            </div>
                            {UserValue==="user"?
                            <>
                                <Grid container>
                                    <Grid item lg={12}>
                                <div className="username">
                                    {/* <input type="text" placeholder="Enter Username" onChange={UserNameFn} style={{height:"35px",paddingLeft:"15px"}} value={UserName} required></input><br/> */}
                                    <TextField onChange={UserNameFn} id="outlined-password-input" label="Enter UserName" value={UserName} type="text"/>
                                    
                                </div>
                                </Grid>
                                <Grid item lg={12}>
                                <div className="password">
                                    {/* <input type="password" placeholder="Password" maxLength="10" onChange={PasswordFn} style={{height:"35px",paddingLeft:"15px"}} value={Password} required></input><br/>  */}
                                    <TextField onChange={PasswordFn} id="outlined-password-input" label="Password" type="password" value={Password} autoComplete="current-password"/><br/>

                                </div>
                                </Grid>
                                </Grid>
                                <Grid container className="loginAndClear">
                                    <Grid item lg={5}>
                                <div className="submit">
                                    {/* <input type="submit" value="LOGIN" style={{cursor:"pointer",marginTop:"12px"}} onClick={Submit}></input> */}
                                    <Button onClick={Submit} variant="contained" style={{backgroundColor:"#243782"}}>LOGIN</Button>
                                </div>
                                </Grid>
                                <Grid item lg={1}>
                                <div className="clear">
                                <input type="submit"  value="CLEAR" style={{cursor:"pointer",marginTop:"12px"}} onClick={Clear}></input>
                                {/* <Button onClick={Clear} variant="contained" style={{backgroundColor:"#243782"}}>CLEAR</Button> */}
                                </div>
                                </Grid>
                                </Grid>
                                </>:
                                <>
                                <Grid container>
                                    <Grid item lg={12}>
                                <div className="username">
                                    {/* <input type="text" placeholder="Enter Admin Username" onChange={UserNameFn} style={{height:"35px",paddingLeft:"15px"}} value={UserName} required ></input><br/> */}
                                    <TextField onChange={UserNameFn} id="outlined-password-input" label="Enter Admin UserName" value={UserName} type="text"/>
                                </div>
                                </Grid>
                                <Grid item lg={12}>
                                <div className="password">
                                    {/* <input type="password" placeholder="Password" onChange={PasswordFn} style={{height:"35px",paddingLeft:"15px"}} maxLength="10"value={Password} required></input><br/>  */}
                                    <TextField onChange={PasswordFn} id="outlined-password-input" label="Password" value={Password} type="password"/><br/>
                                </div>
                                </Grid>
                                </Grid>
                                <Grid container className="loginAndClear">
                                    <Grid item lg={5}>
                                <div className="submit">
                                    {/* <input type="submit" value="ADMIN" style={{cursor:"pointer",marginTop:"12px"}} onClick={Submit}></input> */}
                                    <Button onClick={Submit} style={{backgroundColor:"#243782"}} variant="contained">ADMIN</Button>
                                </div>
                                </Grid>
                                <Grid item lg={1}>
                                <div className="clear">
                                <input type="submit" value="CLEAR" style={{cursor:"pointer",marginTop:"12px"}} onClick={Clear}></input>
                                {/* <Button onClick={Clear} style={{backgroundColor:"#243782"}} variant="contained">CLEAR</Button> */}
                                </div>
                                </Grid>
                                </Grid>
                                </>}
                            </div>
                    </Grid>
                </Grid>  
            </div>
        </div>
        </>
    )
}

export default Login;